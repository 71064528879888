.leaderboards {
  width: 100%;
  height: 100%;
  position: absolute;
  background: radial-gradient(
      130.36% 182.37% at -5.98% -7.78%,
      rgba(49, 186, 244, 0.1) 0%,
      rgba(49, 186, 244, 0) 100%
    ),
    radial-gradient(
      99.26% 141.42% at 100% 100%,
      rgba(68, 57, 226, 0.1) 0%,
      rgba(229, 234, 246, 0) 100%
    ),
    #f4f8ff;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  padding: 1.7em;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
}

.icon {
  width: 100%;
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  height: 30rem;
  flex-shrink: 0;
  margin-top: 5%;
}

.iconContainer {
  width: 230px;
  height: 230px;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.emptyStateIcon {
  position: absolute;
  width: 230px;
  height: 230px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-right: 10px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.mainText {
  width: 100%;
  position: relative;
  font-size: 1.5rem;
  line-height: 32px;
  text-transform: capitalize;
  display: inline-block;
  font-family: 'Satoshi Bold';
  color: #1f3750;
  text-align: center;
  margin-bottom: 10px;
}
.text {
  position: relative;
  font-size: 1.25rem;
  font-family: Satoshi;
  color: #2b4a75;
  text-align: center;
  display: inline-block;
  width: 500px;
}

.emptyStateLeaderboardParent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emptyStateContainer {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}

.bluerSpace {
  height: 90px;
}

.blur {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(11px);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #1f3750;
  font-family: Satoshi;
}

.blurWithGradient {
  background: linear-gradient(0.61deg, #f4f8ff, rgba(244, 248, 255, 0)),
    linear-gradient(0deg, #f4f8ff, rgba(244, 248, 255, 0));
  backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #1f3750;
  font-family: Satoshi;
}
